/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, ButtonCta, SideBySide} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "compact-powerful-and-reliable-in-all-situations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#compact-powerful-and-reliable-in-all-situations",
    "aria-label": "compact powerful and reliable in all situations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Compact, Powerful, and Reliable In All Situations"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you wear hearing aids, you shouldn’t have to settle for second best. The sounds you experience through your hearing aids should be as pure and authentic as the world itself, so why would you choose a device that doesn’t meet your exacting standards?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The solution? The Signia Pure hearing aid – the most compact and powerful hearing aid with superior sound quality in every situation. With three great models to choose from, there’s a Signia Pure hearing aid out there for everyone. Meet the hearing aid that can revolutionize how you hear your world."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ High-quality own voice processing and speech understanding"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Picks up speech in noise"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Bluetooth connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ IP68-rated resistance to moisture, sweat, dust, and dirt"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Housing color can easily be changed"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Built-in tinnitus therapy feature"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Remote control functionality through a smartphone app"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Pure 13 Nx offers optional T-coil connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Pure Charge&Go Nx has a rechargeable lithium-ion battery"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Pure Charge&Go Nx’s OneShell design means no battery door and no contacts"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Pure 312 Nx features a small and compact design for discreet use every day"), "\n", React.createElement(ButtonCta, {
    copy: "Sign up now and try the pure",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "powerful-own-voice-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#powerful-own-voice-technology",
    "aria-label": "powerful own voice technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Powerful Own Voice Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/x",
    className: "c-md-a"
  }, "The Signia Xperience platform "), " is poised to take the hearing aid world by storm. This technology powers Signia’s patented Own Voice Processing (OVP), which means Signia Pure hearing aid wearers can listen to their own voice without the distracting background noise from the world around them."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "By reducing annoying background noise, Signia Pure hearing aid wearers experience their own voice as naturally as possible while also staying attuned to their surroundings. Gone are the days of poor sound quality and unnatural listening experiences with Signia’s Own Voice Processing technology."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "better-conversations-every-time",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#better-conversations-every-time",
    "aria-label": "better conversations every time permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Better Conversations, Every Time"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia_pure.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’ve ever struggled to keep up with a conversation even while wearing your hearing aids, you’re not alone. With the Pure’s directional microphones, your hearing aids can better focus in on the person in front of you for improved speech understanding. This means better, more intimate conversations and fewer “Can you repeat that?” moments in your day. What could be better?")), "\n", React.createElement(ButtonCta, {
    copy: "Start your risk-free trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "stream-directly-from-smartphones-and-tv",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#stream-directly-from-smartphones-and-tv",
    "aria-label": "stream directly from smartphones and tv permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Stream Directly From Smartphones and TV"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re tired of turning the TV volume up just to listen to your favorite shows, the Pure might have the solution you’re looking for. With the Pure hearing aid line, you can stream sound directly from your smartphone or TV, so there’s no need to turn the volume up to dangerously high levels. You can answer phone calls and listen to the commentary on the big football game directly with your Signia Pure hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "moisture-and-dust-resistance-for-your-active-lifestyle",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#moisture-and-dust-resistance-for-your-active-lifestyle",
    "aria-label": "moisture and dust resistance for your active lifestyle permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Moisture and Dust Resistance for Your Active Lifestyle"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Gone are the days of worrying about damaging your hearing aids during a sweaty workout or a day on the beach. The Signia Pure hearing aids are rated to IP-68, which means they’re moisture and dust resistant to match the needs of your active lifestyle. While they aren’t waterproof, you won’t have to worry about damaging just because you stay active."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "customizable-color-options-to-match-your-style",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customizable-color-options-to-match-your-style",
    "aria-label": "customizable color options to match your style permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customizable Color Options to Match Your Style"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-pure312nx.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hearing aids are a part of your everyday life, so why should you be stuck with a drab and dreary color? Thankfully the Signia Pure comes with a whole host of customizable color options so you can find the one that matches your style. Just ask our hearing care provider to switch the color casing on your Pure hearing aids, and you’ll be ready to go.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "remote-control-functionality",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#remote-control-functionality",
    "aria-label": "remote control functionality permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Remote Control Functionality"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Do you hate fiddling around with the small controls on your hearing aids? Do you wish there was an easier way to adjust your settings without having to take them off just to fuss about with some teeny tiny little buttons? That’s why Signia provides their hearing aid wearers with the industry’s most efficient and powerful remote control app so you can make on-the-fly adjustments whenever you need it most."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "groundbreaking-tinnitus-treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#groundbreaking-tinnitus-treatment",
    "aria-label": "groundbreaking tinnitus treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Groundbreaking Tinnitus Treatment"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-loss/tinnitus",
    className: "c-md-a"
  }, "Tinnitus affects millions of people"), " more around the world. The condition has no cure, but several therapies have been known to provide relief to those who need it most."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signia understands the need for a discreet, high-quality tinnitus therapy, which is why they’ve created their unique Notch Therapy technology for their Pure hearing aids. This therapy can produce static noise and ocean wave therapy signals to help reduce the effects of tinnitus in some patients for a higher quality of life and peace of mind."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "bye-bye-batteries",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bye-bye-batteries",
    "aria-label": "bye bye batteries permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bye Bye Batteries"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-pure-charge-go-nx-with-charger.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "If changing hearing aid batteries is a chore you’d rather avoid, you can say bye-bye to batteries with the Signia Pure Charge&Go X. This model in the Pure line-up provides hearing aid wearers with a rechargeable lithium-ion battery that can stream sound all day without the need to change out any fiddly batteries. To recharge the Charge&Go, just pop the hearing aids into the charger at night, and they’ll be ready to go in the morning. It’s as quick, easy, and simple as that.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "smallest-and-most-powerful-in-its-class",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#smallest-and-most-powerful-in-its-class",
    "aria-label": "smallest and most powerful in its class permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Smallest and Most Powerful in its Class"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "No one wants to wear big, clunky hearing aids, so Signia designed their Pure 312 X hearing aids to be some of the smallest on the market. Small and mighty, the Pure 312 X doesn’t skimp on functionality, however, as they provide wearers with the same high-quality listening experience they’ve come to know and love from Signia hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "were-here-to-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#were-here-to-help",
    "aria-label": "were here to help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We’re Here to Help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Are you overwhelmed with all of the different hearing aids options available to you? Do you need guidance to choose the hearing aid that’s right for your needs and style? We’re here to help. Contact us today to speak with one of our hearing consultants and find out if the Signia Pure is the right hearing aid for you. Simply call us or click the “Get started” button below to see if you qualify for a 30 day risk-free trial."), "\n", React.createElement(ButtonCta, {
    copy: "Start your risk-free trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-nx-logo.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-nx-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-nx-technology",
    "aria-label": "signia nx technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/x",
    className: "c-md-a"
  }, "Signia Nx Technology")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Xperience is a revolutionary technology platform from Signia, the company that has taken over Siemens’ well-known range of audiological devices. Available in Behind-the-Ear and Receiver-in-Canal models, Xperience is one of the most advanced hearing aid technologies around.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-silk.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-silk-x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-silk-x",
    "aria-label": "signia silk x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/silk",
    className: "c-md-a"
  }, "Signia Silk X")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re looking for a hearing aid that is as small as a coffee bean and unnoticeable to those around you, the Signia Silk X is the perfect fit. It’s one of the smallest hearing aids on the market today, disappearing inside your ear. These ITE hearing aids also include “Click Sleeves,” which make them simple and comfortable to wear, as they’re made out of soft silicone that molds itself to the shape of your ear canal.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-styletto-dark-granite.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-styletto",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-styletto",
    "aria-label": "signia styletto permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/styletto",
    className: "c-md-a"
  }, "Signia Styletto")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "This slim, behind-the-ear Bluetooth hearing aid has transformed the future of modern hearing aid design. Designed for the active lifestyle, the rechargeable Styletto X hearing aid combines elegance, convenience and functionality to deliver a high-quality hearing experience without compromising style. And no one will know you’re wearing it.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/binaural-hearing-aids.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-pure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-pure",
    "aria-label": "signia pure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/pure",
    className: "c-md-a"
  }, "Signia Pure")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Signia range of Pure hearing aids is incredible no matter your level of hearing loss. With these powerful Bluetooth hearing aids that sit discreetly behind your ear, you’ll be able to stream phone calls, TV and audio right into your ears.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
